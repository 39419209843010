import React from 'react';

const IconLogo = () => (
  <svg id="logo" role="img" viewBox="0 0 84 96" xmlns="http://www.w3.org/2000/svg">
    <title>Logo</title>
    <g transform="matrix(1, 0, 0, 1, -8, -2)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon id="Shape" stroke="currentColor" strokewidth="5" strokelinecap="round" strokelinejoin="round" points="39 0 0 22 0 67 39 90 78 68 78 23"/>
      </g>
    </g>
    <text
          style={{
            fill: 'var(--green)',
            fontFamily: 'Arial, sans-serif',
            fontSize: '41px',
            fontWeight: 700,
            whiteSpace: 'pre',
          }}
          x="27.303"
          y="64.107"
        >
          Y
    </text>
  </svg>
);

export default IconLogo;
